/*Form input lable*/
.formLable{
  font-family:'Montserrat',sans-serif !important;
  font-size: 12px !important;
  color: #333333;
  font-weight: normal;
}

.margin15{
  margin: 0px 15px;
}
  .btnSubmit,.btnUpdate{
    color: #FFF;
    background-color: #275d8e;
    border-radius: 35px;
    margin-bottom: 30px;
    border-top-color: #275d8e;
    font-family:'Roboto', sans-serif !important;
    letter-spacing: 1px;
    box-shadow: 2px 2px 2px #fff;

}
  .tablefthr:hover{
    background: var(--whitesmoke-color) !important;
    color: var(--black-color) !important;
  }
  
  .nopadding {
    padding: 0 !important;
 
 }

  .formht{
    height: 75px;
  }
  .CStabl {
      padding-top:12px;
  }
  .basicinfocmpset{
    height: 215px;
      margin-bottom: 15px;
  }
  .basicinfotxt{
    font-size: 14px !important;
    font-family:'Montserrat',sans-serif !important;
    font-weight: 700;
    color: #333;
    line-height: 1.1;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .Box-Bottom-Header{
  
    border-bottom: 1px solid #eee;
    margin-bottom: 3.5em;
  }
  
  .addcmtset{
    padding:0;
  }
  .contactcatcls{
    text-align: center;
    margin: 10px;
  }
  .tbltdfortr{
      width: 100%;
  }
  .actionth{
      text-align: left!important;
  }
  .roletble1{
     width: 5%;
  }
  .actiondata{
      width: 15%;
  }
  .rsimg{
      height: 50px;
      float: right;
  } 
  .mkpyinpt{
      margin-top: 80px;
  }
  .btnaligncentr{
      text-align: center;
  }
  .rolesField{
    border-radius: 3px!important;
  }
  .branchtd{
    width:100px!important;
  }
  .companysettingbox{
    min-height:385px!important;
  }
  .editbtns2{
      padding:0px 0px!important;
  }
  .roletbl{
    text-align: center!important;
  }
  .editbtns{
    padding:10px 10px!important;
  }
  .editbtnshvr:hover{
    background:#00b8ff!important;
    border-color:#00b8ff!important;
    color:#fff!important;
  }
  .editbtns1{
    padding:5px 5px!important;
  }
  .editbtns2{
    padding:5px 5px!important;
  }
  .addrolesBtnaddroll{
    margin-top: 0px!important;
  }
  .rolelst{
    text-align: center !important;
  
  }
  .CategoriesListUpload {
      font-size: 20px;
      text-align: center;
  }
  .myTable{
    border:1px solid #d3d6de;
  }
  .contentheight{
      min-height:990px!important;
      background:#ecf0f5;
  }
  .editInfo,.editTax{
      height: 29px!important;
      line-height: 16px!important;
  }
  .companysettingbox{
      border:3px solid #bbb!important;
  }
  .compSettingTitle,.compSetingInput{
      padding: 0;
  }
  /**********comapnay setting tabs************/
  .tablebdy{
      padding: 0;
  }
  .compySettingHr{
      border-top: 1px solid #eee;
      width: 100%;
  }
  .companySettingTabs{
      margin-top: 2em;
      margin-bottom: 2em;
  }
  
  
  /*****DN new css******/
  .tabs-left {
    border-bottom: none;
  }
  
  .tabs-left>li {
    float: none;
    margin:0px;
    
  }
  
  .tabs-left>li.active>a,
  .tabs-left>li.active>a:hover,
  .tabs-left>li.active>a:focus {
    border-bottom-color: #ddd;
    border-right-color: transparent;
    background: var(--blue-background) !important;
    color: #fff !important;
    border:none;
    border-radius:2px;
    margin:0px;
    margin-bottom: 2%;
  }
  .nav-tabs>li>a:hover {
      line-height: 1.42857143;
      border: 1px solid transparent;
  }
  .tabs-left>li.active>a::after{content: "";
    position: absolute;
    top: 7.5px;
    right: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    
    border-left: 10px solid var(--blue-background);
    display: block;
    width: 0;
  }
  .companyNamechkbx{
      margin-right: 20px!important;
  }
  .tabLeft{
      background: #fff;
      margin-bottom: 2%;
      color: #333!important;
      border: 1px solid #ccc!important;
      font-family: 'Roboto', sans-serif !important;
  }
  .companySettingMarginTop{
    margin-top: 2%;
  }
  .displayLogo{
    height: 50px;
    width: 50px;
  }
  .companyDisplayForm{
    box-shadow: 1px 1px 2px 1px #ccc;
    padding: 0;
  }
  .compForm{
    padding:0;
  }
  
  .csImageWrapper{
    border: 1px solid #ccc;
    margin-top: 0%;
    padding: 4px 6px 4px 0px;
    color: #fff;
    overflow: hidden;
  }
  .compinfotp{
    margin-top: 40px;
  }
  .csImageWrapper:hover .displayBlockOne, .csImageWrapper:hover .removeprofPhoto{
    display: inline-block;
    transition: 0.4s ease-in-out;
  
  }
  .outerPhotoWrapper{
    border: 0px solid #eee;
    height: 125px;
    padding: 0px;
    background-size: 100% 100%;
    background-position:center;
    text-align: right;
  }
  
  .companyDropdown{
    display: none;
    position: absolute;
    right: 10px !important;
    min-width: 160px;
    box-shadow: 0px 8px 8px 0px #eee;
    z-index: 1;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    margin-top: -4px;
  }
  .compyHeader{
    padding-left: 2em;
  }
  .addMarginTop{
    margin-top: 80px;
  }
  .marginTopM{
    margin-top: -35px;
  }
  .boxTx{
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 3%;
    flex: 1 1;
    font-size: 13px;
    background-color: var(--white-color);
    border: 1px solid #ddd;
  }
  /*****End DN new css******/
  
  