.col14,.col15,.col16{
    width: 200px;
}
.col11,.col12,.col13{
    width: 300px;
}
.col100{
    width: 100px;
}
.pan{
    text-transform: uppercase;
}
.bold{
    font-weight: bold;
}
.reports-list-outerTR {
    background: #266fb9;
    color: #fff;
}

.reports-list-outerTableth, .reports-list-outerTabletd {
    border: 1px solid #266fb9;
}
.sales-report-commonpre{
    display: flex;
    justify-content: space-around;
}
.sales-report-common {
    background: #ccc;
    display: block;
    padding: 10px 30px;
    color: #333;
    cursor: pointer;
    min-width: 15%;
    text-align: center;
}

.sales-report-main-class {
    padding: 10px;
    background: #eee;
}

.reports-select-date-boxmain {
    display: flex;
    justify-content: center;
}
.reports-select-date-boxsec {
    width: 50%;
}

.reports-select-date-Title {
    text-align: center;
    background: #7d7c7c;
    color: #fff;
    padding: 5px;
}

.reportsDateRef {
    text-align: center;
}

.commonReportArrowPoiner{
    cursor: pointer;
}

.report-list-downloadMain {
    display: flex;
    justify-content: flex-end;
}

.report-list-downloadPdf, .report-list-downloadPrint {
    display: inline-block;
}

.report-list-downloadPdf i, .report-list-downloadPrint i {
    margin-right: 10px;
}

.report-list-downloadPdf {
    padding: 5px 10px;
    background: #dd4b39;
    margin-right: 5px;
    color: #fff;
    cursor: pointer;
}

.report-list-downloadPrint {
    padding: 5px 10px;
    background: #4b4e53;
    /* margin-right: 5px; */
    color: #fff;
    cursor: pointer;
}

.report-list-downloadXLXS {
    padding: 5px 10px;
    background: #2189d1;
    color: #fff;
    cursor: pointer;
    border-radius: 0 !important;
}

.report-list-downloadXLXS:hover, .report-list-downloadXLXS:active, .report-list-downloadXLXS:visited {
    background: #2189d1;
    color: #fff;
}

.reports-select-date-from1, .reports-select-date-to1 {
    display: inline-block;
    width: 50%;
}

.reports-select-date-from2, .reports-select-date-to2{
    text-align: center;
}

.reports-table-main {
    margin-top: 20px;
}

.report-currentlyActive {
    background: #2189d1;
    color: #fff;
}


/*=============================*/
.navTabList{
    display: flex;
    justify-content: space-around;
}
.navTab {
    background: #ccc;
    display: block;
    padding: 10px 30px;
    color: #333;
    cursor: pointer;
    min-width: 15%;
    text-align: center;
}
.navListItem {
    width: 100%;
    font-weight: 600;
}
.navListItem .active {
    background: #2189d1;
    color: #fff;
}
.navListItem :hover {
    background: #2189d1 !important;
    color: #fff;
}
.dateTabsWrapper{
    padding: 10px;
    background: #eee;
}
.boxHeight36{
    height: 36px !important;
}
/*.paddingRight0 {
  padding-right:  0;
} 
.borderRight  {
 border-left: 1px solid #eee;
}
.paddingLeft0 {
  padding-left:  0;
} */
.boxBorder  {
    border: 1px solid #ccc;
    padding: 15px;
}
.filtersDiv{
    display: none;
    box-shadow: 0px 1px 1px 1px #cccc;
    padding-bottom: 20px !important;
    transition: 0.5s linear;
}
.css-26l3qy-menu{
    z-index: 101 !important;
}