
.mainicon{
    height: 100px;
border-radius: 2px 2px 2px 2px;
    background-color: #eee;
}
.ccon1{
        height: 99px;
    background-color: #00c0ef;
    font-size: 38px;
    padding-top: 22px;
    padding-left: 42px;
    color: #fff;
    /* padding: 9%; */
    border-radius: 2px 0px 0px 2px;
}
.ccon2{
   height: 85px;
    background-color: #dd4b39;
    font-size: 48px;
    padding-top: 9px;
    padding-left: 30px;
    color: #fff;
    border-radius: 2px 0px 0px 2px;
}
.ccon3{
   height: 85px;
    background-color: #00a65a;
    font-size: 34px;
    padding-top: 20px;
    padding-left: 35px;
    color: #fff;
    border-radius: 2px 0px 0px 2px;
}
.ccon4{
    height: 85px;
    background-color: #f39c12;
    font-size: 34px;
    padding-top: 22px;
    padding-left: 36px;
    color: #fff;
    border-radius: 2px 0px 0px 2px;
}
.per{
    font-size: 18px;
}
.emptybox1{
    height: 15px;
    
}
.emptybox2{
    height: 10px;
    
}
.box1{
    height: 400px;
    background-color: #ffffff;
    border-top: 3px solid #ccc; 
    border-radius: 2px 2px 2px 2px;
    
}
.box1a{
    border-bottom:0.5px solid #eee;
}
.box1a h4{
    font-weight: 700;
    color : #d71111d6;
    text-align: center;
}
.box1b{
    height: 265px;
    border-bottom:0.5px solid #eee;
}
.box1icon{
    font-size: 12px;
    padding-top: 10px;
    color: #aaa;
    font-weight: bolder;

}
.box1icon:hover{   
    color: #000;

}
.progress{
    height: 10px    ;
    border-radius: 2px;
}
.pbar1{
    width: 80%;
    background-color: #00c0ef;
}
.pbar2{
    width: 80%;
    background-color: #dd4b39;
}
.pbar3{
    width: 80%;
    background-color: #00a65a;
}
.pbar4{
    width: 80%;
    background-color: #f39c12;
}
    
.box1bottom{
     border-right:0.5px solid #eee;
}
.textgreen{
    color: #00a65a;
}
.textorange{
    color: #f39c12;
}
.textred{
    color: #dd4b39;
}

.box2{
  background-color: #ffffff;
  border-radius: 2px 2px 2px 2px;
}
.bggreen
{
    background-color: #00a65a;
    height: 349px;
}
.box3{
    height: 101px;
    
}
.box3a{
    height: 90px;
    background-color: #f39c12;
    color: #ffffff;
    line-height: 25px;
    border-radius: 2px 2px 2px 2px;
}

.box3aicon{
    color: #fff;
    font-size: 40px;
    padding-top: 20px;
    padding-left: 35px;
    height: 90px;
    background-color: #c27d0e;
    border-radius: 2px 2px 2px 2px;
}

.prog{
    height: 4px !important;
    background-color: #555;
    
}
.progbar1{
    width: 50%;
    background-color: #ffffff;
}
.progbar2{
    width: 20%;
    background-color: #ffffff;
}
.progbar3{
    width: 70%;
    background-color: #ffffff;
}
.progbar4{
    width: 40%;
    background-color: #ffffff;
}
.increase{
    line-height: 00px !important;
}
.bgyellow{
   background-color:  #f39c12;
}

.box4{
    height: 345px;
    background-color: #ffffff;
    border-top: 3px solid  #f39c12; 
    border-radius: 2px 2px 2px 2px;
    
}
.box4a{
    height: 250px;
     border-bottom:0.5px solid #eee;
}
.box4btn1{
    background-color: #f39c12;
}
.box4btn1:hover{
    background-color: #f28b01;
}

.box5{
    height: 335px;
    background-color: #ffffff;
    border-top: 3px solid  #dd4b39; 
    border-radius: 2px 2px 2px 2px;
    
}
.labeldanger{
    height: 15px;
    background-color: #dd4b39;
    padding-top: 4px;

}
.box5a{
    height: 250px;
     border-bottom:0.5px solid #eee;
}
.box5text{
    color: #3c8dcb;
    
}
.box5text:hover{

    color: #5eafad;
}
.box6{
    height: 365px;
    background-color: #ffffff;
    border-top: 3px solid  #cccccc; 
    border-radius: 2px 2px 2px 2px;
    
}
.box6a{
    height: 181px;
     border-bottom:0.5px solid #eee;
}
.box6b{
    height: 37px;
     border-bottom:0.5px solid #eee;
}

.box7{
    height: 410px;
    background-color: #ffffff;
    border-top: 3px solid #00c0ef; 
    border-radius: 2px 2px 2px 2px;
    
}

.box7label{
    position: none !important;

}
.box7foot{
    border-top:0.5px solid #eee;
    
}
.box7btn{

    border-radius: 0px;
}
.box8text{
    color: #aaa;
    font-size: 11px;
}

.box8b{
    height: 63px;
     border-bottom:0.5px solid #eee;
}
.box8{
    height: 411px;
    background-color: #ffffff;
    border-top: 3px solid  #3c8dbc; 
    border-radius: 2px 2px 2px 2px;
}
.foot{
    padding-top: 20px;
    height: 50px;
    border-top: 1px solid #ccc;
    background-color: #ffffff;

}
.tach{
    font-size: 11px;
    color: #000;
    padding-top: 15px;
}
.breadcrumb{
    background-color: #eee !important;
}
