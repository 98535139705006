.mb15{
    margin-bottom: 15px
}
.dashContent{
    background: #ecf0f5;
    padding-bottom: 15px;
    min-height: 1420px !important;
}
.mainicon{
    height: 100px;
    background-color: #fff;
    margin-bottom: 15px;
    margin-top: 20px;
    border: 1px solid #eee;
     -webkit-box-shadow: 0 0 10px #ddd; 
    box-shadow: 0 0 10px #ddd;  
    border-radius: 5px;
}
.subIcon{
    height: 50px;
    width: 100%;
    background-color: #fff;
/*    margin-bottom: 15px;*/
    border: 1px solid #eee;
     -webkit-box-shadow: 0 0 10px #ddd; 
    box-shadow: 0 0 10px #ddd;  
    border-radius: 5px;
}
.purple-gradient {
    background: linear-gradient(40deg,#ff6ec4,#7873f5) !important;
}
.aqua-gradient {
    background: linear-gradient(40deg,#2096ff,#05ffa3) !important;
}
.peach-gradient {
    background: linear-gradient(40deg,#ffd86f,#fc6262) !important;
}
.green-gradient {
    background: linear-gradient(40deg,#ffff00fc,#29710cba) !important;
}
.blue-gradient {
    background: linear-gradient(40deg,#6effc9,#7873f5) !important;;
}

.collectionText{
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}
.white{
    color: #fff;
}
.collectionBox{
    height: 80px;
    padding: 25px;
    margin-bottom: 30px;
    margin-top: 20px;
    border: 1px solid #eee;
    -webkit-box-shadow: 0 0 10px #ddd; 
    box-shadow: 0 0 10px #ddd;  
    border-radius: 5px;
}
/* .approvalCount{
    border-radius:50%; 
    border: 1px solid #ff0;
 }  */
.approvalCount {
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    display: flex;
    border: 1px solid #fff;
    height: 30px;
} 
.blue-text{
/*    color: #7873f5;*/
    font-size: 16px;
    font-weight: 600;
}
.approvalBox{
    height: 40px;
    padding: 5px;
    background-color: #fff;
    margin-top: 8px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px #ddd;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
}
.userBox{
    height: 100px;
    background-color: #fff;
    margin-bottom: 30px;
    margin-top: 20px;
    border: 1px solid #eee;
     -webkit-box-shadow: 0 0 10px #ddd; 
    box-shadow: 0 0 10px #ddd;  
    border-radius: 5px;
    font-size: 14px;
}
.statusHeading{
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    padding-top: 15px;
}
.statusHeading2{
    text-align: center;
    font-size: 13px;
    color: #555;
/*    font-weight: 600;*/
    padding-top: 15px;
}
.statusHeading3{
    font-size: 13px;
    color: #555;
/*    font-weight: 600;*/
    padding-top: 15px;
}
.statusBox{
    /*padding-top: 5px;
    padding-right: 0;*/
    background-color: #ffbb33;
    border-bottom: 1px solid #fff; 
    height: 50px;
}
.statusBox2{
    border-right: 3px solid #eee; 
    height: 50px;
}
.ccon1{
        height: 99px;
    background-color: #00c0ef;
    font-size: 38px;
    padding-top: 22px;
    padding-left: 42px;
    color: #fff;
    /* padding: 9%; */
    border-radius: 2px 0px 0px 2px;
}
.ccon2{
   height: 85px;
    background-color: #dd4b39;
    font-size: 48px;
    padding-top: 9px;
    padding-left: 30px;
    color: #fff;
    border-radius: 2px 0px 0px 2px;
}
.ccon3{
   height: 85px;
    background-color: #00a65a;
    font-size: 34px;
    padding-top: 20px;
    padding-left: 35px;
    color: #fff;
    border-radius: 2px 0px 0px 2px;
}
.ccon4{
    height: 85px;
    background-color: #f39c12;
    font-size: 34px;
    padding-top: 22px;
    padding-left: 36px;
    color: #fff;
    border-radius: 2px 0px 0px 2px;
}
.per{
    font-size: 14px;
}
.per1{
    font-size: 12px;
}

.dashboardHeading1{
    font-size: 12px;
}
.emptybox1{
    height: 15px;
    
}
.emptybox2{
    height: 10px;
    
}
.box1{
    height: 400px;
    background-color: #ffffff;
    border-top: 3px solid #ccc; 
    border-radius: 2px 2px 2px 2px;
    
}
.box1a{
    border-bottom:0.5px solid #eee;
}
.box1b{
    height: 265px;
    border-bottom:0.5px solid #eee;
}
.box1icon{
    font-size: 12px;
    padding-top: 10px;
    color: #aaa;
    font-weight: bolder;

}
.box1icon:hover{   
    color: #000;

}
.progress{
    height: 10px    ;
    border-radius: 2px;
}
.pbar1{
    width: 80%;
    background-color: #00c0ef;
}
.pbar2{
    width: 80%;
    background-color: #dd4b39;
}
.pbar3{
    width: 80%;
    background-color: #00a65a;
}
.pbar4{
    width: 80%;
    background-color: #f39c12;
}
    
.box1bottom{
     border-right:0.5px solid #eee;
}

.box2{
     position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid var(--blue-color );
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    
}
.horibox{
    height: 420px;
    background-color: #ffffff;
    border: 1px solid #eee;
     -webkit-box-shadow: 0 0 10px #ddd; 
    box-shadow: 0 0 10px #ddd;  
    border-radius: 5px;
    margin-top: 15px;

}
.bggreen
{
    background-color: #00a65a;
    height: 349px;
}


.box3{
    height: 101px;
    
}
.box3a{
    height: 90px;
    background-color: #f39c12;
    color: #ffffff;
    line-height: 25px;
    border-radius: 2px 2px 2px 2px;
}

.box3aicon{
    color: #fff;
    font-size: 40px;
    padding-top: 20px;
    padding-left: 35px;
    height: 90px;
    background-color: #c27d0e;
    border-radius: 2px 2px 2px 2px;
}

.prog{
    height: 4px !important;
    background-color: #555;
    
}
.progbar1{
    width: 50%;
    background-color: #ffffff;
}
.progbar2{
    width: 20%;
    background-color: #ffffff;
}
.progbar3{
    width: 70%;
    background-color: #ffffff;
}
.progbar4{
    width: 40%;
    background-color: #ffffff;
}
.increase{
    line-height: 00px !important;
}
.bgyellow{
   background-color:  #f39c12;
}

.box4{
    height: 345px;
    background-color: #ffffff;
    border-top: 3px solid  #f39c12; 
    border-radius: 2px 2px 2px 2px;
    
}
.box4a{
    height: 250px;
     border-bottom:0.5px solid #eee;
}
.box4btn1{
    background-color: #f39c12;
}
.box4btn1:hover{
    background-color: #f28b01;
}

.box5{
    height: 335px;
    background-color: #ffffff;
    border-top: 3px solid  #dd4b39; 
    border-radius: 2px 2px 2px 2px;
    
}
.labeldanger{
    height: 15px;
    background-color: #dd4b39;
    padding-top: 4px;

}
.box5a{
    height: 250px;
     border-bottom:0.5px solid #eee;
}
.box5text{
    color: #3c8dcb;
    
}
.box5text:hover{

    color: #5eafad;
}
.box6{
    height: 365px;
    background-color: #ffffff;
    border-top: 3px solid  #cccccc; 
    border-radius: 2px 2px 2px 2px;
    
}
.box6a{
    height: 181px;
     border-bottom:0.5px solid #eee;
}
.box6b{
    height: 37px;
     border-bottom:0.5px solid #eee;
}

.box7{
    height: 410px;
    background-color: #ffffff;
    border-top: 3px solid #00c0ef; 
    border-radius: 2px 2px 2px 2px;
    
}

.box7label{
    position: none !important;

}
.box7foot{
    border-top:0.5px solid #eee;
    
}
.box7btn{

    border-radius: 0px;
}
.box8text{
    color: #aaa;
    font-size: 11px;
}

.box8b{
    height: 63px;
     border-bottom:0.5px solid #eee;
}
.box8{
    height: 411px;
    background-color: #ffffff;
    border-top: 3px solid  #3c8dbc; 
    border-radius: 2px 2px 2px 2px;
}
.foot{
    padding-top: 20px;
    height: 50px;
    border-top: 1px solid #ccc;
    background-color: #ffffff;

}
.tach{
    font-size: 11px;
    color: #000;
    padding-top: 15px;
}
.breadcrumb{
    background-color: #eee !important;
}

.chartBox1{
    height: 380px;
    box-shadow: 0px 0px 3px #aaa;
    border-radius: 5px;
    font-size: 0.9vw;
    font-family: var(--font1);
    padding: 15px;
}


.chartBox{
    height: 380px;
    box-shadow: 0 0 10px #ddd;
    border-radius: 5px;
    font-size: 0.9vw;
    font-family: var(--font1);
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 20px;

}
.callsBox{
    height: 110px;
    box-shadow: 0 0 10px #ddd;
    border-radius: 5px;
    font-size: 14px;
    font-family: var(--font1);
    padding: 20px;
    margin-bottom: 30px;

}
.callsBox1{
    height: 110px;
    box-shadow: 0 0 10px #ddd;
    border-radius: 5px;
    font-size: 14px;
    font-family: var(--font1);
    padding: 20px;
    margin-bottom: 30px;

}
.mx-auto{
    padding-top: 1rem!important;
    margin-right: auto!important;
    margin-left: auto!important;
}

/***************************************New Styles *********************************************************/
.title{
  font-family: 'Roboto', sans-serif !important;
  font-weight: bold;
  font-size: 16px;
}

.smallBox1{
    width: 22%;
    height: 100px;
    box-shadow: 0px 0px 3px #aaa;
    border-radius: 5px;
    font-size: 0.9vw;
    font-family: var(--font1);
}

.smallBox2{
    width: 22%;
    margin-left: 3%;
    height: 100px;
    box-shadow: 0px 0px 3px #aaa;
    border-radius: 5px;
    font-size: 0.9vw;
    font-family: var(--font1);
}

.container{
    padding-left: 20px;
    padding-right: 20px;
}

.triangleRight1 {
    position: absolute;
    top: 35px;
    left: 92px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent; 
    border-left: 15px solid #79DFC1;
    border-bottom: 15px solid transparent;
}

.triangleRight2 {
    position: absolute;
    top: 35px;
    left: 92px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent; 
    border-left: 15px solid #FBC230;
    border-bottom: 15px solid transparent;
}

.triangleRight3 {
    position: absolute;
    top: 35px;
    left: 92px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent; 
    border-left: 15px solid #3D8BFC;
    border-bottom: 15px solid transparent;
}

.triangleRight4 {
    position: absolute;
    top: 35px;
    left: 92px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent; 
    border-left: 15px solid #E35D6A;
    border-bottom: 15px solid transparent;
}

.boxPadding{
    padding: 0% 6% 0% 0%;
}

.smallBoxB1{
    background-color: #79DFC1;
    height: 100px;
    border-radius: 5px;
    box-shadow: 5px 0px 0px #5ab99d;
}

.smallBoxB2{
    background-color: #FBC230;
    height: 100px;
    border-radius: 5px;
    box-shadow: 5px 0px 0px #e0b547;
}

.smallBoxB3{
    background-color: #3D8BFC;
    height: 100px;
    border-radius: 5px;
    box-shadow: 5px 0px 0px #5181c5;
}

.smallBoxB4{
    background-color: #E35D6A;
    height: 100px;
    border-radius: 5px;
    box-shadow: 5px 0px 0px #ac4658d1;
}

.iconImg{
    margin-top: 48%;
    margin-left: 30%;
}

.chartBox1{
    width: 48%;
    margin-right: 4%;
    height: 380px;
    box-shadow: 0px 0px 3px #aaa;
    border-radius: 5px;
    font-size: 0.9vw;
    font-family: var(--font1);
    padding: 15px;
}


.chartBox2{
    height: 380px;
    box-shadow: 0px 0px 3px #aaa;
    border-radius: 5px;
    font-size: 0.9vw;
    font-family: var(--font1);
    padding: 15px;
}

.boxNum{
    font-size: 16px;
    font-family: var(--font3);
    font-weight: bold;
}

.fromDate{
    margin-left: 30%;
}

.toDate{
    margin-left: 7%;
}

.customControlInput {
    left: 7px !important;
    top: 2px !important;
    z-index: 1 !important;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.customControlInput:checked~.customControlLabel::before {
    color: #fff;
    border-color: var(--color2) !important;
    background-color: var(--color2) !important;
}
.custom-control-label{
    margin-top: -20px;
}
.calendar{
    border: 2px solid #999;
    height: 200px;
    background-color: #fff;
    box-shadow: 3px 3px 6px #aaa;
}

.dateText{
    text-align: center !important;
}

/* ************ Start Media Query ************ */

@media (max-width: 359px){
    .chartBox1{
        width: 100%;
        margin-right: 0%;
        height: 200px;
        padding: 25px;
        margin-bottom: 30px;
    }

    .chartBox2{
        width: 100%;
        height: 300px;
    }

    .smallBox1{
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        font-size: 3vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .smallBox2{
        width: 100%;
        margin-left: 0%;
        font-size: 3vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .mobileGap{
        margin-left: 0%;        
    }

    .mobileGap1{
        margin-right: 0%;
        margin-left: 0%;
    }

    .smallBoxB1{
        height: 80px;
    }

    .smallBoxB2{
        height: 80px;
    }

    .smallBoxB3{
        height: 80px;
    }

    .smallBoxB4{
        height: 80px;
    }

    .triangleRight1 {
        top: 25px;
        left: 64px;
    }

    .triangleRight2 {
        top: 25px;
        left: 63px;
    }

    .triangleRight3 {
        top: 25px;
        left: 64px;
    }

    .triangleRight4 {
        top: 25px;
        left: 63px;
    }

    .boxNum{
        font-size: 3vw;
    }

    .textFont{
        font-size: 3vw;
    }
}

@media (min-width:360px) and (max-width: 410px){
    .mrlT{
        margin-left: 75px;
    }
    .ml20{
/*        margin-left: -20px*/
    }
    .chartBox1{
        width: 100%;
        margin-right: 0%;
        height: 230px;
        padding: 10px;
        margin-bottom: 30px;
    }

    .chartBox2{
        width: 100%;
        height: 350px;
        margin-bottom: 30px;
    }

    .smallBox1{
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        font-size: 3vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .smallBox2{
        width: 100%;
        margin-left: 0%;
        font-size: 3vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .mobileGap{
        margin-left: 0%;        
    }

    .mobileGap1{
        margin-right: 0%;
        margin-left: 0%;
    }

    .smallBoxB1{
        height: 80px;
    }

    .smallBoxB2{
        height: 80px;
    }

    .smallBoxB3{
        height: 80px;
    }

    .smallBoxB4{
        height: 80px;
    }

    .triangleRight1 {
        top: 25px;
        left: 60px;
    }

    .triangleRight2 {
        top: 25px;
        left: 60px;
    }

    .triangleRight3 {
        top: 25px;
        left: 60px;
    }

    .triangleRight4 {
        top: 25px;
        left: 60px;
    }

    .boxNum, .collectionText{
        font-size: 16px;
        text-align: center;
    }

    .textFont{
        font-size: 2.5vw;
    }

    .title{
        margin-bottom: 15px;
    }
    .collectionBox{
        padding: 16px;
    }
    .approvalBox{
        height: 70px;
        padding-top: 20px;
    }
    .chartBox{
        height: 600px;
    }
}

@media (min-width:411px) and (max-width: 576px){
    .mrlT{
        margin-left: 105px;
    }
    .ml20{
/*        margin-left: -20px*/
    }
    .chartBox1{
        height: 260px;
        margin-right: 0%;
        width: 100%;
        padding: 25px;
        margin-bottom: 30px;
    }
    .chartBox2{
        width: 100%;
    }
    .smallBox1{
        width: 44%;
        margin-left: 0%;
        margin-right: 4%;
        font-size: 2vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .smallBox2{
        width: 44%;
        margin-left: 8%;
        font-size: 2vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .mobileGap{
        margin-left: 8%;        
    }

    .mobileGap1{
        margin-right: 4%;
        margin-left: 0%;
    }

    .smallBoxB1{
        height: 80px;
    }

    .smallBoxB2{
        height: 80px;
    }

    .smallBoxB3{
        height: 80px;
    }

    .smallBoxB4{
        height: 80px;
    }

    .triangleRight1 {
        top: 25px;
        left: 44px;
    }

    .triangleRight2 {
        top: 25px;
        left: 43px;
    }

    .triangleRight3 {
        top: 25px;
        left: 44px;
    }

    .triangleRight4 {
        top: 25px;
        left: 43px;
    }

    .boxNum, .collectionText{
        font-size: 12px;
        text-align: center;
    }

    .textFont{
        font-size: 2vw;
    }

    .title{
        margin-bottom: 15px;
    }
}

@media (min-width:577px) and (max-width: 767px){

    .chartBox1{
        width: 100%;
        margin-right: 0%;
        height: 425px;
        padding: 25px;
        margin-bottom: 30px;
    }
    .chartBox2{
        width: 100%;
    }
    .smallBox1{
        width: 44%;
        margin-left: 0%;
        margin-right: 4%;
        font-size: 1.5vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .smallBox2{
        width: 44%;
        margin-left: 8%;
        font-size: 1.5vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .mobileGap{
        margin-left: 8%;        
    }

    .mobileGap1{
        margin-right: 4%;
        margin-left: 0%;
    }

    .smallBoxB1{
        height: 80px;
    }

    .smallBoxB2{
        height: 80px;
    }

    .smallBoxB3{
        height: 80px;
    }

    .smallBoxB4{
        height: 80px;
    }

    .triangleRight1 {
        top: 25px;
        left: 55px
    }

    .triangleRight2 {
        top: 25px;
        left: 55px
    }

    .triangleRight3 {
        top: 25px;
        left: 55px
    }

    .triangleRight4 {
        top: 25px;
        left: 55px
    }

    .boxNum{
        font-size: 1.5vw;
    }

    .textFont{
        font-size: 1.5vw;
    }
    /*.mrlT{
        margin-left: 105px;
    }*/
    .approvalCount{
        height: 40px;
    }
    .approvalBox{
        height: 70px;
        padding-top: 16px;
    }
    .chartBox{
        height: 600px;
    }
}


@media (min-width:768px) and (max-width: 991px){

    .chartBox1{
        width: 100%;
        margin-right: 0%;
        height: 425px;
        padding: 25px;
        margin-bottom: 30px;
    }
    .chartBox2{
        width: 100%;
        }
    .smallBox1{
        width: 44%;
        margin-left: 0%;
        margin-right: 4%;
        font-size: 1.5vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .smallBox2{
        width: 44%;
        margin-left: 8%;
        font-size: 1.5vw;
        margin-bottom: 25px;
        height: 80px;
    }

    .mobileGap{
        margin-left: 8%;        
    }

    .mobileGap1{
        margin-right: 4%;
        margin-left: 0%;
    }

    .smallBoxB1{
        height: 80px;
    }

    .smallBoxB2{
        height: 80px;
    }

    .smallBoxB3{
        height: 80px;
    }

    .smallBoxB4{
        height: 80px;
    }

    .triangleRight1 {
        top: 25px;
        left: 63px;
    }

    .triangleRight2 {
        top: 25px;
        left: 66px;
    }

    .triangleRight3 {
        top: 25px;
        left: 66px;
    }

    .triangleRight4 {
        top: 25px;
        left: 66px;
    }

    .boxNum{
        font-size: 1.5vw;
    }

    .textFont{
        font-size: 1.5vw;
    }
    .approvalCount{
        height: 45px;
    }
    .approvalBox{
        height: 70px;
    }
    .chartBox{
        height: 600px;
    }
        
}

@media (min-width:992px) and (max-width: 1023px){
    .triangleRight1, .triangleRight2, .triangleRight3, .triangleRight4{
        left: 73px;
    }
    .approvalCount{
        height: 60px;
    }
    .approvalBox{
        height: 70px;
    }
}


@media (min-width:1024px) and (max-width: 1279px){
    .triangleRight1, .triangleRight2, .triangleRight3, .triangleRight4{
        left: 73px;
    }
    .approvalCount{
        height: 60px;
    }
    .approvalBox{
        height: 70px;
    }
    .chartBox{
        overflow: scroll;
    }
}

@media (min-width:1280px) and (max-width: 1365px){
    .collectionBox{
        padding: 0;
    }
     .collectionText{
        font-size: 14px;
        padding-top: 30px !important;
    }
     .boxNum{
        font-size: 14px;
        padding-top: 30px;
    }
}


@media (min-width:1366px) and (max-width: 1439px){
    .collectionText{
        font-size: 14px;
    }
    .boxNum{
        font-size: 14px;
    }

}

@media (min-width:1440px) and (max-width: 1679px){
    .triangleRight1, .triangleRight2, .triangleRight3, .triangleRight4{
        left: 108px;
    }
}

@media (min-width:1680px) and (max-width: 1919px){
    .triangleRight1, .triangleRight2, .triangleRight3, .triangleRight4{
        left: 119px;
    }
    .smallBoxB1, .smallBox1, .smallBoxB2, .smallBox2, .smallBoxB3, .smallBox3, .smallBoxB4, .smallBox4{
        height: 110px;
    }
    .approvalCount{
        height: 36px;
    }
    .approvalBox{
        height: 70px;
        padding-top: 17px;
    }   
    .chartBox{
        height: 600px;
    } 
}
@media (min-width:1080px) and (max-width: 1920){
    .triangleRight1, .triangleRight2, .triangleRight3, .triangleRight4{
        left: 78px;
    }
}

@media (min-width:1920px) and (max-width: 2559px){
    .triangleRight1, .triangleRight2, .triangleRight3, .triangleRight4{
    left: 135px;
    }
    .smallBoxB1, .smallBoxB2, .smallBoxB3, .smallBoxB4{
        margin-left: -5px;
    }
    .approvalCount{
        height: 40px;
    }
    .approvalBox{
        height: 70px;
        padding-top: 17px;
    } 
    .chartBox{
        height: 600px;
    }
}


@media (min-width:2560px)and (max-width: 2737px){
    .boxPadding{
        padding: 0% 3%;
    }
    .approvalCount{
        height: 60px;
    }
    .chartBox{
        height: 690px;
    }
    .approvalBox{
        height: 80px;
        padding-top: 17px;
    } 
    .smallBox1, .smallBox2, .smallBox3, .smallBox4
    {
        height: 140px;
    } 
    .smallBoxB1, .smallBoxB2, .smallBoxB3, .smallBoxB4
    {
        height: 140px;
    }
    .triangleRight1, .triangleRight2, .triangleRight3, .triangleRight4{
        top: 53px;
    left: 197px;
    }
}
@media(min-width: 2737px){
    .triangleRight1, .triangleRight2, .triangleRight3, .triangleRight4{
        top: 53px;
    left: 210px;
    }
    }
    .marginTop40{
        margin-top: 40px;
    }
.mt-30{
    margin-top: 30px;
}