.pageWrapper {
    padding: 30px;
    box-shadow: 0px 0px 5px #aaa;
    margin-top: 30px;
    border-radius: 10px;
}
.p-5 {
    padding: 3rem!important;
}
.mt-5, .my-5{
    margin-top: 3rem!important;
}
.mb15{
    margin-bottom: 15px!important;
}
.border {
    border: 1px solid #dee2e6!important;
}
.bg-light {
    background-color: #f8f9fa!important;
}
.disclaimer{
    font-family: 'Poppins-Light';
    font-size: 14px;
    text-align: justify;
}
.blogTitle{
	font-family: var(--font2);
    font-weight: bold;
    font-size: 24px;
    text-align: center;

}
.userImg{
    border-radius: 50%;
    height: 50px;
    width: 50px;
}
.pubDate{
    font-size: 1vw;
    font-family: var(--font3);
}
.userIcon{
	font-size: 15px;
	color: var(--color5);
}
.appIcon{
	color: var(--color1);
    margin-right: 15px;
}
.category{
	font-size: 12px;
	color: var(--color5);
}
.heroImg{
	width: 100%;
    height: 350px;
    border-radius: 7px;
}
.descFont img{
    width: 100% !important;
}
.descFont{
	font-size: 1.4vw;
    font-family: var(--font2);
    text-align: justify !important;
    width: 100%;
}
.words{
	font-size: 16px;
	font-family: var(--font2);
}
.shortDesc{
	font-style: italic;
    font-size: 16px;
    text-align: justify;
}
.subImg{
	width: 100%;
	height: 130px;
	border-radius: 7px;
}
.blogUl{
	list-style: square;
}
.feedbackUser{
	background: var(--color2);
    border-radius: 50%;
    height: 30px;
    width: 30px;
}
.nameInitials{
  padding: 7px;
  display: block;
}
.replyLink{
	
    color: #007bff;
}
.msgBox{
	min-height: 100px;
    border-radius: 7px !important;
    border: 1px solid #ccc !important;
}
.shareIcon{
    display: block;
    margin: auto;
    height: 35px;
    width: 35px;
}
.shareIconTwitter{
    display: block;
    margin: auto;
    height: 30px;
    width: 30px;
}
.heartIcon{
    width: 25px;
    height: 25px;
}
.pageProgressBar{
    z-index: 99;
    left: 0px;
    height: 6px;
    background-color: var(--color2);
}

.heart{
    height: 65%;
    width: 55%;
    cursor: pointer;
}
.heart :hover{
    color: var(--color2);
    box-shadow: 0x 0x 6px var(--color2);   
}
.mt10{
    
    margin-top: -10px;
}
.deleteBlog{
    cursor: pointer;
}
.deleteBlog:hover{
    color: #f00;
}


/* ************ Start Media Query ************ */

@media (max-width: 359px){
    .blogTitle{
        font-size: 16px;
    }

    .heroImg{
        height: 250px;
    }

    .heart{
        height: 40%;
        width: 50%;
    }

    .userImg{
        height: 30px;
        width: 30px;
    }

    .pubDate{
        font-size: 2.5vw;
    }

    .category{
        font-size: 2.8vw;
    }

    .userIcon{
        font-size: 2.8vw;
    }

    .descFont img{
        height: 100px !important;
        width: 100px !important;
    }

    .descFont{
        font-size: 4vw;
    }

    .descFont h1{
        font-size: 1.7rem;
    }

    .descFont h2{
        font-size: 1.2rem;
    }

    .descFont h3{
        font-size: 0.95rem;
    }

    .descFont h4{
        font-size: 0.7rem;
    }

    .descFont h5{
        font-size: 0.7rem;
    }

    .descFont h6{
        font-size: 0.7rem;
    }
}

@media (min-width:360px) and (max-width: 410px){
    .blogTitle{
        font-size: 16px;
    }

    .heroImg{
        height: 250px;
    }

    .heart{
        height: 40%;
        width: 45%;
    }

    .userImg{
        height: 30px;
        width: 30px;
    }

    .pubDate{
        font-size: 10px;
    }

    .category{
        font-size: 11px;
    }

    .userIcon{
        font-size: 2.8vw;
    }

    .descFont img{
        height: 100px !important;
        width: 100px !important;
    }

    .descFont{
        font-size: 3.5vw;
    }

    .descFont h1{
        font-size: 1.7rem;
    }

    .descFont h2{
        font-size: 1.2rem;
    }

    .descFont h3{
        font-size: 0.95rem;
    }

    .descFont h4{
        font-size: 0.7rem;
    }

    .descFont h5{
        font-size: 0.7rem;
    }

    .descFont h6{
        font-size: 0.7rem;
    }
}

@media (min-width:411px) and (max-width: 576px){
    .blogTitle{
        font-size: 16px;
    }

    .heroImg{
        height: 250px;
    }

    .heart{
        height: 40%;
        width: 50%;
    }

    .userImg{
        height: 30px;
        width: 30px;
    }

    .pubDate{
        font-size: 10px;
    }

    .category{
        font-size: 11px;
    }

    .userIcon{
        font-size: 2.8vw;
    }

    .descFont img{
        height: 100px !important;
        width: 100px !important;
    }

    .descFont{
        font-size: 3.5vw;
    }

    .descFont h1{
        font-size: 1.7rem;
    }

    .descFont h2{
        font-size: 1.2rem;
    }

    .descFont h3{
        font-size: 0.95rem;
    }

    .descFont h4{
        font-size: 0.7rem;
    }

    .descFont h5{
        font-size: 0.7rem;
    }

    .descFont h6{
        font-size: 0.7rem;
    }
}

@media (min-width:577px) and (max-width: 767px){
    .blogTitle{
        font-size: 16px;
    }

    .heart{
        height: 40%;
        width: 50%;
    }

    .userImg{
        height: 30px;
        width: 30px;
    }

    .pubDate{
        font-size: 10px;
    }

    .category{
        font-size: 11px;
    }

    .userIcon{
        font-size: 2.8vw;
    }

    .descFont img{
        height: 100px !important;
        width: 100px !important;
    }

    .descFont{
        font-size: 3.5vw;
    }

    .descFont h1{
        font-size: 1.7rem;
    }

    .descFont h2{
        font-size: 1.2rem;
    }

    .descFont h3{
        font-size: 0.95rem;
    }

    .descFont h4{
        font-size: 0.7rem;
    }

    .descFont h5{
        font-size: 0.7rem;
    }

    .descFont h6{
        font-size: 0.7rem;
    }
}


@media (min-width:768px) and (max-width: 991px){
    .heart{
        height: 60%;
        width: 70%;
    }

    .pubDate{
        font-size: 1.5vw;
    }
}

@media (min-width:992px) and (max-width: 1023px){
}


@media (min-width:1024px) and (max-width: 1279px){

}

@media (min-width:1280px) and (max-width: 1365px){
    .descFont{
        font-size: 1vw;
    }

    .descFont h1{
        font-size: 3rem;
    }

    .descFont h2{
        font-size: 1.5rem;
    }

    .descFont h3{
        font-size: 1.2rem;
    }

    .descFont h4{
        font-size: 1rem;
    }

    .descFont h5{
        font-size: 1rem;
    }

    .descFont h6{
        font-size: 1rem;
    }
}


@media (min-width:1366px) and (max-width: 1439px){
    .descFont{
        font-size: 1.1vw;
    }

    .descFont h1{
        font-size: 3rem;
    }

    .descFont h2{
        font-size: 1.5rem;
    }

    .descFont h3{
        font-size: 1.2rem;
    }

    .descFont h4{
        font-size: 1rem;
    }

    .descFont h5{
        font-size: 1rem;
    }

    .descFont h6{
        font-size: 1rem;
    }

}

@media (min-width:1440px) and (max-width: 1679px){
    .descFont{
        font-size: 1.1vw;
    }

    .descFont h1{
        font-size: 3rem;
    }

    .descFont h2{
        font-size: 1.5rem;
    }

    .descFont h3{
        font-size: 1.2rem;
    }

    .descFont h4{
        font-size: 1rem;
    }

    .descFont h5{
        font-size: 1rem;
    }

    .descFont h6{
        font-size: 1rem;
    }

    .category{
        font-size: 1vw;
    }

    .userIcon{
        font-size: 1vw;
    }
}

@media (min-width:1680px) and (max-width: 1919px){
    .descFont{
        font-size: 1.1vw;
    }

    .descFont h1{
        font-size: 3rem;
    }

    .descFont h2{
        font-size: 2rem;
    }

    .descFont h3{
        font-size: 1.5rem;
    }

    .descFont h4{
        font-size: 1.2rem;
    }

    .descFont h5{
        font-size: 1.2rem;
    }

    .descFont h6{
        font-size: 1.2rem;
    }

    .category{
        font-size: 1vw;
    }

    .userIcon{
        font-size: 1vw;
    }
}

@media (min-width:1920px) and (max-width: 2559px){
    .descFont{
        font-size: 1vw;
    }

    .descFont h1{
        font-size: 3rem;
    }

    .descFont h2{
        font-size: 1.8rem;
    }

    .descFont h3{
        font-size: 1.2rem;
    }

    .descFont h4{
        font-size: 1rem;
    }

    .descFont h5{
        font-size: 1rem;
    }

    .descFont h6{
        font-size: 1rem;
    }

    .category{
        font-size: 1vw;
    }

    .userIcon{
        font-size: 1vw;
    }
}


@media (min-width:2560px){
    .descFont{
        font-size: 1.1vw;
    }

    .descFont h1{
        font-size: 3.5rem;
    }

    .descFont h2{
        font-size: 3rem;
    }

    .descFont h3{
        font-size: 2.8rem;
    }

    .descFont h4{
        font-size: 2.5rem;
    }

    .descFont h5{
        font-size: 2.5rem;
    }

    .descFont h6{
        font-size: 2.5rem;
    }

    .category{
        font-size: 1vw;
    }

    .userIcon{
        font-size: 1vw;
    }
}